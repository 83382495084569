<template>
  <div class="full-screen">
    <img @click="goNextLesson" class="good-bye" src="./images/goodbye.png" />
    <div class="custom-audio">
      <audio ref="endAudio" :src="audioSrc" controls></audio>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesson-end",
  props: {
    nextLessonPath: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      audioSrc: require("@/assets/music/end.mp3"),
    };
  },
  mounted() {
    this.$refs.endAudio.play();
    this.$on("beforeDestroy", () => {
      this.$refs.endAudio.pause();
    });
  },
  methods: {
    goNextLesson() {
      if (this.nextLessonPath) {
        this.$router.push(this.nextLessonPath);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.full-screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* filter:blur(20px) drop-shadow(0.5); */
  backdrop-filter: 10px;
  z-index: 100;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: inherit;
    // filter: blur(10px);
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    backdrop-filter: blur(4px);
  }
  .good-bye {
    position: relative;
    width: 800px;
    z-index: 9;
    cursor: pointer;
  }
  .custom-audio {
        position: absolute;
        left: 50%;
        bottom: 50px;
        transform: translateX(-50%);
        z-index: 10;
    }
}
</style>